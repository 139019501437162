<template>
  <v-bottom-sheet
    v-model="showMergeFields"
    content-class="custom-bottom-sheet"
    min-height="80vh"
    height="80vh"
  >
  <v-row no-gutters class="drawer-wrapper p-0">
        <v-col cols="12" class="drawer-header">
            <span></span>
            <span class="drawer-heading">Merge Fields</span>
            <div class="close-icon" @click="onCloseClick">
                <HbIcon mdi-code="mdi-close" color="white" />
            </div>
        </v-col>
        <v-col cols="12" class="drawer-content">
            <div class="pa-4">
                <HbTextField search v-model="mergeSearch" v-validate="'required\max:45'" data-vv-scope="default"
                    data-vv-name="text-field-search" data-vv-as="Text Field Search" @click:clear="clearSearch"
                    :error="errors.has('default.text-field-search')" placeholder="Search Merge Fields" />
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3 class="mx-6 mt-4 mb-5 ">All Merge Fields</h3>
            <ul class="list-view">
                <!-- Iterate over merge fields -->
                <li v-for="(field, index) in filteredFields" :key="index" class="list-item"
                    @click="addMergeField(field.token)">
                    <div class="mt-3 mb-3 hb-text-light">{{ field.token }}</div>
                    <div class="tooltip-label">
                        <hb-tooltip>
                            <template v-slot:item>
                                <div class="plus-icon">
                                    <HbIcon mdi-code="mdi-information" small />
                                </div>
                            </template>
                            <template v-slot:body>
                                {{ field.label }}
                            </template>
                        </hb-tooltip>
                    </div>
                </li>
            </ul>
        </v-col>
    </v-row>
  </v-bottom-sheet>
</template>

<script>
import { MergeFields } from "./MergeFields";
export default {
  data() {
    return {
      mergeSearch: "",
      allMergeFields: MergeFields.mergeFields,
    };
  },
  methods: {
    clearSearch() {
      this.mergeSearch = "";
    },
    addMergeField(token) {
      this.$emit("addMergeField", token, this.context);
      this.$emit("onCloseClick");
    },
    onCloseClick() {
      this.$emit("onCloseClick");
    },
  },
  computed: {
    filteredFields() {
      const searchQuery = (this.mergeSearch || "").trim().toLowerCase();
      const filtered = this.allMergeFields.filter(
        (field) =>
          field.label.toLowerCase().includes(searchQuery) ||
          field.label.toLowerCase().includes(searchQuery)
      );
      return filtered;
    },
  },
  props: {
    showMergeFields: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.custom-bottom-sheet {
  /* height: 100vh; */
}

.drawer-wrapper{
    background-color: white !important;
    display: flex;
}

.drawer-header {
  height: 40px;
  background: var(--Primary-Brand-Darker, #003135);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
}

.drawer-heading {
  color: #ffffff;
  font-size: medium;
}

.drawer-content {
  margin-top: 12px;
  overflow-y: auto;
}

.close-icon {
  margin-right: 16px;
  cursor: pointer;
}

.list-view {
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  padding: inherit;
}

.list-item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 24px;
}

:hover.list-item {
  background: var(--Primary-Cloud-Lighter, #f9fafb);
}

:active.list-item {
  background: var(--Primary-Brand-Lighter, #e0f5f5);
}

.tooltip-label {
  padding-right: 14px;
}
</style>
