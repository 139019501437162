<template>
    <div>
        
        <hb-modal
            size="large"
            title="Browse Files"
            v-model="showForm"  
            @close="$emit('close')" 
        >
        <template v-slot:content >           
          <v-app style="max-height: 70vh;overflow-y: hidden;">
            <v-navigation-drawer app color=	#F9FAFB
            width="200" class="inside-card-drawer" disable-resize-watcher                  
                  fixed
                  elevation-0
                  mobile-breakpoint="415"
                  >
          <v-list >
            <v-list-item
              v-for="(option, index) in options"
              :key="index"
              @click="selectOption(index)"
              :class="{ 'selected': selectedOption === index }"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px; display: flex; flex-direction: column;"><span>{{ option.label }}</span><span v-if="option.subLabel">{{ option.subLabel }}</span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
         
          </v-list>
        </v-navigation-drawer>
    <v-main style="padding: 0 0 0 200px;">
      <div v-if="selectedOption==0">
        <uploadNonSignedDocuments @docLoadingemit="loadingDoc" @docSelectedemit="selectedDoc" :contactid="contactid" :propertyView="propertyid" :contactLeases="leases" ></uploadNonSignedDocuments>  
      </div>
      <div v-if="selectedOption==1">
        <uploadSignedDocuments @docLoadingemit="loadingDoc" @docSelectedemit="signLink" :contactid="contactid" :propertyView="propertyid" :contactLeases="leases" ></uploadSignedDocuments>  
      </div>
      <div v-if="selectedOption==2">
        <uploadInvoices @docLoadingemit="loadingDoc" @docSelectedemit="selectedDoc" :contactid="contactid" :propertyView="propertyid" :contactLeases="leases" ></uploadInvoices>  
      </div>
      <div v-if="selectedOption==3">
        <uploadReceipts @docLoadingemit="loadingDoc" @docSelectedemit="selectedDoc" :contactid="contactid" :propertyView="propertyid" :contactLeases="leases" ></uploadReceipts>  
      </div>
      <div v-if="selectedOption==4">
        <uploaddocs @docSelectedemit="selectedDoc" :contactid="contactid" :propertyView="propertyid" :contactLeases="leases" ></uploaddocs>  
      </div>
    </v-main>
  </v-app>
</template>
        <template v-slot:right-actions>
            <hb-btn :disabled="attachButtonDisabled" :loading="loading" @click="attachdoc()">Attach</hb-btn>
        </template>      
        </hb-modal>
    </div>
    </template>            
    
    <script type="text/babel">
    import uploaddocs from '../contacts/uploaddocs.vue'
    import uploadInvoices from '../contacts/uploadInvoices.vue'
    import uploadSignedDocuments from '../contacts/uploadSignedDoc.vue'
    import uploadNonSignedDocuments from '../contacts/uploadNonSignedDoc.vue'
    import uploadReceipts from '../contacts/uploadReceipts.vue'
    export default {
        name: "UploadAttachmentEmail",
        props:['propertyid','contactid','leases','uploadDocumentAttachments','selectedTypeOfDoc','attachSignLink'],
        data() {
            return {
              src:"",
              signUrl:"",
              files:[],
                options: [
                  { label: 'Generate Documents', subLabel:' (Non-Signature)'},     
                  { label: 'Generate Documents', subLabel:' (Signature)'},
                  { label: 'Invoices'},
                  { label: 'Receipts'},
                  { label: 'Tenant Documents'}
                  
                ],

                selectedOption: null, // Index of the selected option
                showForm: true,
                form_panels: [0],             
                loading: false,      
                attachButtonDisabled:true      
            }
        },
        components:{
          uploaddocs,uploadInvoices,uploadReceipts,uploadSignedDocuments,uploadNonSignedDocuments
        },
        computed: {
          
        },
        created(){
               this.selectedOption=this.selectedTypeOfDoc
        },
        mounted() {
            
        },
        methods: {
          attachdoc(){
            if(this.signUrl !== ""){
              this.loading=true
              this.attachSignLink(this.signUrl)
              this.loading=false
            }else{
                  this.loading=true
                  this.uploadDocumentAttachments(this.files) 
                  this.loading=false
            }
          },
          loadingDoc(l){
            this.attachButtonDisabled=false
            this.loading=l;
          },
          async signLink(item){
            this.signUrl = item
            if(this.signUrl && this.signUrl !==""){
              this.attachButtonDisabled=false;
            }else{
              this.attachButtonDisabled=true;

            }
          },
          selectedDoc(doc){
          console.log(doc);
           if (doc.src) {
                    this.files=doc
                    this.attachButtonDisabled=false
                  }else{
                    this.attachButtonDisabled=true
                  }
                  if (doc) {
                            this.files=doc
                            this.attachButtonDisabled=false
                          }else{
                            this.attachButtonDisabled=true
                          }
                
          },
            selectOption(index) {
         // Handle the selected option
            this.selectedOption = index;
            this.attachButtonDisabled=true
    }
          
        },
    }
    </script>
    <style scoped>
    .selected {
      /* background-color: rgba(0, 0, 255, 0.1); Highlight selected option */
      background: #e0f5f5;
    border-left: 4px solid #00848E;
    width: 100%
    }
    .custom-list {
  max-width: 200px; /* Adjust the maximum width as needed */
}
  
.inside-card-drawer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.v-application--wrap{
  min-height: 100%;
}

    </style>